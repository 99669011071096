<template>
  <b-tabs content-class="pt-2" justified>
    <b-tab :title="`Documents ${DocumentsCount > 0 ? '('+DocumentsCount+')' : ''}`">
      <b-card-text>
        <autres-document v-if="projetData.etude" :from-child="true" :devis-id-doc="projetData.id" :devis-type-doc="projetData.etude.source" @ajouter-document="$emit('ajouter-document', projetData.etude_id, projetData.id, projetData.devisable_id, projetData.devisable.type, true)"
        :isDocumentAdded="isDocumentAdded"
        @initial-docs-refreshed="initialDocsRefreshed"
        @count-docs-refreshed="countDocsRefreshed"
        :list-type="optionsType" />
      </b-card-text>
    </b-tab>
    <b-tab :title="`Notes ${NotesCount > 0 ? '('+NotesCount+')' : ''}`">
      <b-card-text>
        <list-notes  :item-chosen="itemChosen"   @count-notes-refreshed="countNotesRefreshed"  />
      </b-card-text>
    </b-tab>
    <b-tab title="Activités" disabled>
      <b-card-text>
        <list-activite v-if="projetData.etude" :client-type="projetData.devisable.type" :client-id="projetData.devisable.id" :refresh-data-activite="refreshDataActivite" @reset-refresh-data-activite="resetRefreshDataActivite" :item-chosen="itemChosen" />
      </b-card-text>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText, BButton } from 'bootstrap-vue'
import AutresDocument from '@/views/projets/AutresDocument.vue'
import documentService from '@/shared/services/documentService'
import listNotes from '@/views/projets/projet/details-fiche-projet/notes/Note.vue'
import listActivite from '@/views/tiers/global-components/Activite.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    BButton,
    AutresDocument,
    listNotes,
    listActivite
  },
  props: {
    projetData: {
      type: Object,
      required: true,
    },
    isDocumentAdded: {
      type: Boolean,
      default: false,
      required: false
    },
    isNotesAdded: {
      type: Boolean,
      default: false,
      required: false
    },
    refreshDataActivite: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      DocumentsCount: 0,
      NotesCount :0,
      famille: null,
      optionsType: [],
      itemChosen: {
        typeInterface: 'devis',
        itemId: null,
        clientId: null,
        clientType: null
      },
     
    }
  },
  watch: {
    optionsFamille: {
      deep:true,
      handler(val){
          this.famille = val.find(item => item.value.id == 4).value
          this.changeListoptionType()
      },
    },
    projetData: {
      deep:true,
      handler(val) {
        if(val) {
          this.itemChosen.itemId      = val.id
          this.itemChosen.clientId    = val.devisable.id
          this.itemChosen.clientType  = val.devisable.type
        }
      }
    }
  },
  methods: {
    initialDocsRefreshed(refreshed) {
      this.$emit('initial-docs-refreshed', refreshed)
    },
    initialNotesRefreshed(refreshed) {
      this.$emit('initial-notes-refreshed', refreshed)
    },
    countDocsRefreshed(countDocs){
      this.DocumentsCount=countDocs
    },
    countNotesRefreshed(NotesCount){
      this.NotesCount=NotesCount
    },
    changeListoptionType() {
      if (this.famille) {
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.optionsType = []
      }
    },
    resetRefreshDataActivite(isRefresh) {
      this.$emit('reset-refresh-data-activite', isRefresh)
    }
  },
  setup() {
    const optionsFamille = ref([])
    const fetchDocumentNatureWithTypes = documentService()
    fetchDocumentNatureWithTypes
      .then(res => {
      optionsFamille.value = []
        res.data.filter(function(a) {return a.id == 4;}).forEach(el => {
          optionsFamille.value.push({
            value: { id: el.id, typeDocument: el.documentype },
            text: el.libelle
          })
        })
      })
      .catch(errors => {
        console.error(errors)
        optionsFamille.value = []
      })

    return {
      fetchDocumentNatureWithTypes,
      optionsFamille

    }
  }
}
</script>
